// Put your code below this line.

import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import {
  Button,
  Flex,
  Heading,
  SelectField,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { generateClient } from 'aws-amplify/api';

const humanizeDuration = require("humanize-duration");

const client = generateClient();

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      type: 'time',
      ticks: {
        maxTicksLimit: 10,
      }
    },
    y: {
      beginAtZero: true,
      suggestedMax: 1,
    }
  }
};

// Function to format a timedelta in seconds using Luxon
function formatTime(seconds) {
  return humanizeDuration(1000*seconds.toFixed(0), { largest: 2 });
}

const App = ({ signOut }) => {
  const [depths, setDepth] = useState([]);
  const [cycles, setCycle] = useState([]);
  const [device_id, setDeviceId] = useState("");

  useEffect(() => {
    const updateDepth = async () => {
      fetchDepth();
    };

    const interval = setInterval(updateDepth, 5000);

    return () => clearInterval(interval);
  }, [device_id]);

  async function fetchDepth() {
    if (device_id != "") {
      const depthByTSDescending = `query MyQuery {
        depthByTimestamp(device_id: "${device_id}", limit: 50, sortDirection: DESC) {
          items {
            timestamp_utc
            depth_in
            reboot_count
            firmware_version
          }
        }
      }`;
      const apiData = await client.graphql({ query: depthByTSDescending });
      const depthFromAPI = apiData.data.depthByTimestamp.items;
      setDepth(depthFromAPI);
    }
  }

  useEffect(() => {
    const updateCycle = async () => {
      fetchCycle();
    };

    const interval = setInterval(updateCycle, 5000);

    return () => clearInterval(interval);
  }, [device_id]);

  function median(numbers) {
    const sorted = Array.from(numbers).sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2;
    }

    return sorted[middle];
  }

  async function fetchCycle() {
    if (device_id != "") {
      const cycleByTSDescending = `query MyQuery {
        cycleByTimestamp(device_id: "${device_id}", limit: 50, sortDirection: DESC) {
          items {
            pump_start_utc
            pump_min_in
            pump_max_in
            pump_time_s
            cycle_time_s
          }
        }
      }`;
      const apiData = await client.graphql({ query: cycleByTSDescending });
      const cycleFromAPI = apiData.data.cycleByTimestamp.items;
      const updatedCycles = cycleFromAPI.map((cycle, index) => {
        // Calculate the time difference with the next cycle in minutes
        const cycle_time_calc_s =
          index < cycleFromAPI.length - 1
            ? ((new Date(cycle.pump_start_utc) - new Date(cycleFromAPI[index + 1].pump_start_utc)) / 1000)
            : null; // No time difference for the last row

        let start = index - 2 > 0 ? index - 2 : 0;
        let stop = index + 2 + 1;
        let subset_pump_time = cycleFromAPI.slice(start, stop).map(cycle2 => cycle2.pump_time_s);
        const median_pump_time_s = median(subset_pump_time);

        const duty_cycle = median_pump_time_s / cycle_time_calc_s;

        // Return the modified cycle object, adding the timeDifferenceMinutes property
        return {
          ...cycle,
          cycle_time_calc_s,
          median_pump_time_s,
          duty_cycle,
        };
      });

      setCycle(updatedCycles);
    }
  }

  useEffect(() => {
    fetchCycle();
    fetchDepth();
  }, [device_id]);


  return (
    <View className="App">
      <Heading level={1} style={{ textAlign: "left", margin: "0 1.5rem", color: "#072652", fontWeight: "bold" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src="logo_fat.png" height="60" style={{ marginRight: "0.5rem" }} />
          <span>sumpwise</span>
        </div>
      </Heading>


      {/* device selector */}
      <View margin="2rem 1rem" style={{ maxWidth: "400px" }}>
        <SelectField
          label="Device"
          labelHidden
          placeholder="Choose Device"
          value={device_id}
          onChange={(e) => setDeviceId(e.target.value)}
          options={['SUMP_TEST_DEVICE_001', 'SUMP_TEST_DEVICE_002']}
        ></SelectField>
        {depths.slice(0, 1).map((depth) => (
          <p style={{ textAlign: "left", margin: "1rem 0rem" }}>Reboot Count: {depth.reboot_count}</p>
        ))}
        {depths.slice(0, 1).map((depth) => (
          <p style={{ textAlign: "left", margin: "1rem 0rem" }}>Firmware Version: {depth.firmware_version}</p>
        ))}
      </View>


      {/* depth chart and table */}
      <Heading level={2} style={{ textAlign: "left", margin: "1rem 2rem" }}>Depth</Heading>
      <View margin="0 1rem">
        <Line options={options} height={400} data={{
          datasets: [{
            label: 'Depth',
            borderColor: "#3e95cd",
            data: depths.map((depth) => ({
              x: new Date(depth.timestamp_utc).getTime(),
              y: depth.depth_in.toFixed(1)
            })),
          }]
        }} />
      </View>
      <View margin="3rem 3rem">
        <Table
          caption=""
          highlightOnHover={false}>
          <TableHead>
            <TableRow>
              <TableCell as="th" style={{ textAlign: "left" }}>Timestamp</TableCell>
              <TableCell as="th" style={{ textAlign: "left" }}>Depth (in)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {depths.slice(0, 10).map((depth) => (
              <TableRow>
                <TableCell style={{ textAlign: "left" }}>{new Date(depth.timestamp_utc).toLocaleString()}</TableCell>
                <TableCell style={{ textAlign: "left" }}>{depth.depth_in.toFixed(1)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </View>


      {/* cycle chart and table */}
      <Heading level={2} style={{ textAlign: "left", margin: "0 2rem" }}>Cycles</Heading>
      {/* <View margin="0 1rem">
        <Line options={options} height={400} data={{
          datasets: [{
            label: 'Cycle Time (min)',
            borderColor: "#3e95cd",
            data: cycles.map((cycle) => ({
              x: new Date(cycle.pump_start_utc).getTime(),
              y: (cycle.cycle_time_s / 60).toFixed(2)
            })),
          }]
        }} />
      </View> */}
      <View margin="2rem 1rem">
        <Table
          caption=""
          highlightOnHover={false}>
          <TableHead>
            <TableRow>
              <TableCell as="th" style={{ textAlign: "left" }}>Pump Start</TableCell>
              {/* <TableCell as="th">Pump Time (s)</TableCell> */}
              <TableCell as="th">Max Depth (in)</TableCell>
              <TableCell as="th">Min Depth (in)</TableCell>
              <TableCell as="th">Cycle Time</TableCell>
              <TableCell as="th">Duty Cycle (%)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cycles.slice(0, 10).map((cycle, index) => {
              // Calculate the difference between the current and previous cycle times, in minutes
              const timeDifferenceMinutes =
                cycle.cycle_time_calc_s
                  // ? (cycle.cycle_time_calc_s / 60).toFixed(1)
                  ? formatTime(cycle.cycle_time_calc_s)
                  : "-"; // No cycle time for the last row

              const dutyCycle =
                cycle.duty_cycle
                  ? (100 * cycle.duty_cycle).toFixed(1) + "%"
                  : "-"; // No duty cycle for the last row

              return (
                <TableRow key={cycle.pump_start_utc}>
                  <TableCell style={{ textAlign: "left" }}>
                    {new Date(cycle.pump_start_utc).toLocaleString()}
                  </TableCell>
                  {/* <TableCell>{cycle.pump_time_s.toFixed(1)}</TableCell> */}
                  <TableCell>{cycle.pump_max_in.toFixed(2)}</TableCell>
                  <TableCell>{cycle.pump_min_in.toFixed(2)}</TableCell>
                  <TableCell>{timeDifferenceMinutes}</TableCell>
                  <TableCell>{dutyCycle}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </View>


      <Button onClick={signOut}>Sign Out</Button>
    </View>
  );
};

export default withAuthenticator(App);